import React, {useEffect, useState} from "react";
import {
    Chip,
} from '@mui/joy';
import {DataGrid} from "@mui/x-data-grid";
import axios from "axios";


const JobManagement = () => {
    const [csrfToken, setCsrfToken] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [jobRunning, setJobRunning] = useState([]);
    const [jobQueue, setJobQueue] = useState([]);
    const [waitingQueue, setWaitingQueue] = useState([]);

    useEffect(() => {
        axios.get('/api/get-csrf-token/')
            .then(response => {
                setCsrfToken(response.data.csrfToken);
            });
    }, []);

    useEffect(() => {
        const fetchJobQueue = () => {
            axios.get('/api/get_job_queue/') // Adjust this endpoint as necessary
                .then(response => {
                    const updatedJobQueue = response.data.response_data.new_queue;
                    const currentRunningJob = response.data.response_data.current_running;
                    const waitingJob = response.data.response_data.waiting_queue;
                    setJobQueue(updatedJobQueue);
                    setWaitingQueue(waitingJob);
                    setJobRunning(currentRunningJob);
                })
                .catch(error => console.error("Error fetching job queue:", error));
        };

        const intervalId = setInterval(fetchJobQueue, 2000);

        // Cleanup on component unmount
        return () => clearInterval(intervalId);
    }, [jobQueue, jobRunning]);


    const handleRowSelection = (row) => {
        // If the row is already selected, remove it from the selection
        if (selectedRows.includes(row.id)) {
            setSelectedRows(selectedRows.filter(id => id !== row.id));
        } else {
            setSelectedRows([row.id]);
        }
    };

    const columns = [
        {field: 'id', headerName: 'Job Id', width: 300},
        {field: 'module', headerName: 'Module Name', width: 250},
        {
            field: 'arrivalTime',
            headerName: 'Arrival Time',
            width: 200,
            renderCell: (params) => {
                const utcDate = new Date(params.value + 'Z');

                return utcDate.toLocaleString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false // Use 24-hour format
                });
            }
        },
        {field: 'duration', headerName: 'Estimated Duration', width: 200},
        {field: 'priority', headerName: 'Priority', width: 200},
        {field: 'owner', headerName: 'Owner', width: 200}
    ];

    const QueueColumns = [
        {field: 'id', headerName: 'Job Id', width: 300},
        {field: 'module', headerName: 'Module Name', width: 250},
        {
            field: 'arrivalTime',
            headerName: 'Arrival Time',
            width: 200,
            renderCell: (params) => {
                const utcDate = new Date(params.value + 'Z');

                return utcDate.toLocaleString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false // Use 24-hour format
                });
            }
        },
        {field: 'duration', headerName: 'Estimated Duration', width: 200},
        {field: 'waitTime', headerName: 'Estimated Waiting Time', width: 200},
        {field: 'priority', headerName: 'Priority', width: 100},
        {field: 'owner', headerName: 'Owner', width: 200}
    ];


    return (
        <div className="page-container">
            <h1>Job Execution Manager</h1>
            <div className="main-content">
                <div>
                    <Chip>Currently Running</Chip>
                </div>
                <br></br>
                <div style={{height: 108 + (3 * 52.28) + 'px', width: '100%'}}>
                    <DataGrid
                        sx={{  // remove the outline on focus in datagrid
                            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                outline: "none !important",
                            },  // remove the header selector
                            "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                                display: "none"
                            }
                        }}
                        rows={jobRunning}
                        columns={columns}
                        checkboxSelection={false}
                        disableRowSelectionOnClick
                        localeText={{noRowsLabel: "Currently no running jobs"}}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 3,
                                },
                            },
                        }}
                    />
                </div>
                <br></br>
                <div>
                    <Chip>Waiting Queue</Chip>
                </div>
                <br></br>
                <div style={{height: 108 + (5 * 52.28) + 'px', width: '100%'}}>
                    <DataGrid
                        sx={{  // remove the outline on focus in datagrid
                            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                outline: "none !important",
                            },  // remove the header selector
                            "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                                display: "none"
                            }
                        }}
                        rows={waitingQueue}
                        columns={QueueColumns}
                        checkboxSelection
                        rowSelectionModel={selectedRows}
                        onCellClick={handleRowSelection}
                        localeText={{noRowsLabel: "Currently no jobs in the waiting queue"}}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                    />
                </div>
                <br></br>
                <div>
                    <Chip>Job Queue</Chip>
                </div>
                <br></br>
                <div style={{height: 108 + (5 * 52.28) + 'px', width: '100%'}}>
                    <DataGrid
                        sx={{  // remove the outline on focus in datagrid
                            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                outline: "none !important",
                            },  // remove the header selector
                            "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                                display: "none"
                            }
                        }}
                        rows={jobQueue}
                        columns={QueueColumns}
                        checkboxSelection
                        rowSelectionModel={selectedRows}
                        onCellClick={handleRowSelection}
                        localeText={{noRowsLabel: "Currently no jobs in the queue"}}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default JobManagement;
