import React, {useState} from "react";
import './cloudStorage.css';
import {DataGrid} from '@mui/x-data-grid';
import {Box, Button, ButtonGroup, IconButton, InputAdornment, TextField,} from '@mui/material';
import {Search} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';


function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = [' Bytes', ' KB', ' MB', ' GB', ' TB', ' PB', ' EB', ' ZB', ' YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}


const FileDemoPage = ({username}) => {
    const [actionTaken, setActionTaken] = useState(false);  // track whether an action (download or delete) has been initiated
    const [selectedRows, setSelectedRows] = useState([]);  // track which rows have been selected
    const [selectedButton, setSelectedButton] = useState('my storage');  // track the buttons in the side nav
    const [searchTerm, setSearchTerm] = useState('');  // track the search item
    const [rows, setRows] = useState([  // track the rows in the file list
    ]);
    const columns = [
        {field: 'type', headerName: 'Type', flex: 0.4},
        {field: 'fileName', headerName: 'File Name', flex: 3},
        {
            field: 'size',
            headerName: 'Size',
            type: 'number',
            flex: 0.5,
            valueFormatter: (params) => formatBytes(params.value),
        },
    ];

    const navigate = useNavigate();

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleFileUpload = (e) => {
        const files = Array.from(e.target.files);
        let newRows = [...rows];  // Copy the current rows

        files.forEach((file, index) => {
            let uniqueFileName = file.name;

            // Check if the file already exists in rows
            const fileParts = file.name.split('.');
            const extension = fileParts.pop();  // Get the file extension
            let baseName = fileParts.join('.');

            let counter = 1;
            while (newRows.find(row => row.fileName === uniqueFileName)) {
                uniqueFileName = `${baseName}_(${counter}).${extension}`;
                counter++;
            }

            const newRow = {
                id: newRows.length + 1 + index,  // Create a unique ID for the new row
                type: 'File',
                fileName: uniqueFileName,
                size: file.size,
            };
            newRows.push(newRow);
        });

        setRows(newRows);

        // Reset the value of the input to allow the same file to be selected again
        e.target.value = null;
    };


    const handleDirectoryUpload = (e) => {

    };

    const handleFileDownload = () => {

    };

    const handleFileDelete = () => {
        setRows(rows.filter(row => !selectedRows.includes(row.id)));
        setActionTaken(true);
        setSelectedRows([]);
    };

    const handleFileUnzip = () => {
        // Implement unzip logic here
        console.log("Unzipping selected files...");
    };

    const handleDoubleClick = (params) => {  // handler for the double click item in the table
        if (params.row.type === 'Folder') {
            navigate('/'); // replace with your desired path
        }
    };


    const handleRowSelection = (row) => {
        setActionTaken(false);
        // If the row is already selected, remove it from the selection
        if (selectedRows.includes(row.id)) {
            setSelectedRows(selectedRows.filter(id => id !== row.id));
        } else {
            setSelectedRows([...selectedRows, row.id]);
        }
    };

    const handleColumnHeaderClick = (params) => {
        setActionTaken(false);
        // If the checkbox column header is clicked
        if (params.field === '__check__') {
            // If there are any selected rows, deselect all. Otherwise, select all.
            if (selectedRows.length > 0) {
                setSelectedRows([]);
            } else {
                setSelectedRows(rows.map(row => row.id));
            }
        }
    };

    return (
        <div className="page-container">
            <h1>Welcome, {username}!</h1>
            <div className="flex-container">
                <div className="left-nav">
                    <TextField
                        fullWidth
                        size="small"
                        label="Search the Storage"
                        variant="outlined"
                        value={searchTerm}
                        onChange={handleChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton>
                                        <Search/>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Box mt={2}>
                        <ButtonGroup
                            fullWidth
                            variant="contained"
                            color="primary"
                            orientation="vertical"
                            sx={{
                                fontSize: "larger", // or a specific size like "20px"
                                '& .MuiButton-root': {
                                    padding: "12px 24px",  // Adjust padding as needed
                                }
                            }}
                        >
                            <Button
                                onClick={() => {
                                    setSelectedButton('my storage')
                                    navigate('/storage');
                                }}
                                variant={selectedButton === 'my storage' ? 'contained' : 'outlined'}
                            >
                                My Storage
                            </Button>
                            <Button
                                onClick={() => {
                                    setSelectedButton('share with me');
                                    navigate('/share-with-me')
                                }}
                                variant={selectedButton === 'share with me' ? 'contained' : 'outlined'}
                            >
                                Shared with Me
                            </Button>
                            <Button
                                onClick={() => {
                                    setSelectedButton('trash');
                                    navigate('/trash');
                                }}
                                variant={selectedButton === 'trash' ? 'contained' : 'outlined'}
                            >
                                Trash
                            </Button>
                        </ButtonGroup>
                    </Box>
                </div>
                <div className="main-content">
                    <Box display="flex" flexDirection="row" justifyContent="flex-start" mb={2} gap={2}>
                        <input
                            type="file"
                            onChange={handleFileUpload}
                            id="file-upload"
                            multiple
                            style={{display: 'none'}}
                        />
                        <label htmlFor="file-upload">
                            <Button variant="outlined" component="span">
                                Upload Files
                            </Button>
                        </label>
                        <input
                            type="file"
                            directory=""
                            webkitdirectory=""
                            onChange={handleDirectoryUpload}
                            id="directory-upload"
                            style={{display: 'none'}}
                        />
                        <label htmlFor="directory-upload">
                            <Button variant="outlined" component="span">
                                Upload Directory
                            </Button>
                        </label>
                        {selectedRows.length > 0 && !actionTaken && (
                            <>
                                <Button variant="outlined" onClick={handleFileDelete}>
                                    Delete
                                </Button>
                                <Button variant="outlined" onClick={handleFileDownload}>
                                    Download
                                </Button>
                            </>
                        )}
                    </Box>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSizeOptions={[5, 10]}
                        checkboxSelection
                        onCellClick={handleRowSelection}
                        onColumnHeaderClick={handleColumnHeaderClick}
                        onCellDoubleClick={handleDoubleClick}
                    />
                </div>
            </div>
        </div>
    );
};

export default FileDemoPage;
