import React, {useState, useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import './login.css';
import axios from 'axios';
import {v4 as uuidv4} from "uuid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

const Login_MetCraft = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [csrfToken, setCsrfToken] = useState(null);
    const [sessionId, setSessionId] = useState(null);
    const navigate = useNavigate();  // hook for programmatic navigation
    const location = useLocation();
	const [originalOpener, setOriginalOpener] = useState(null);

    useEffect(() => {

        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        let current_path = params.get('current_path');
        const page_mode_MetCraft = params.get('page_mode');
		const receivedSessionId = params.get('sessionId');
        const callback_url =  decodeURIComponent(params.get('callback_url'));

        if (window.opener) {
            window.myGlobalOpener = window.opener;
        }

        axios.get('/api/get-csrf-token/')
            .then(response => {
                setCsrfToken(response.data.csrfToken);
            });

        if (token) {
            const job_id = uuidv4();
            axios.defaults.headers.post['X-CSRFToken'] = csrfToken;

            axios.post(
                "/api/login_Omics/",
                {
                    parameters: {token_Omics: token, username: username, start_datetime: new Date(), mode: page_mode_MetCraft},
                    request_name: 'login_Omics',
                    job_id: job_id
                },
                {
                    headers: {'X-CSRFToken': csrfToken},
                    withCredentials: true
                })
                .then(response => {
                    if (response.data.status === "success") {
                        const data_info = response.data.response_data;
                        const storageKey = data_info.user.storage_key;
                        const userName = data_info.user.first_name;
                        const storageSpace = data_info.storage_space;
                        const accountStatus = data_info.user.status;
                        const userId = data_info.user.account_id;

                        if (accountStatus === 0) {
                            alert("This account have been disabled.")
                            return;
                        }

                        if (current_path === 'None'){
                            current_path = 'test_space/' + storageKey + '/' + 'useruploads' + '/';
                        }
                        // Based on the selected mode, navigate to the appropriate page with the relevant data
                        if (page_mode_MetCraft === 'project_selection' || page_mode_MetCraft === 'project_management') {
                            // Fetch project data and navigate to the project page
                            axios.post(
                                `/api/project/${storageKey}/`,
                                {
                                    parameters: {
                                        folder_name: 'project',
                                        encrypted_id: storageKey
                                    },
                                    request_name: 'enter_project',
                                    job_id: uuidv4()
                                },
                                {
                                    headers: {'X-CSRFToken': csrfToken},
                                    withCredentials: true
                                }
                            ).then(projectResponse => {
                                if (projectResponse.data.status === "success") {
                                    const projectData = projectResponse.data.response_data;
                                    const userDataForProject = {
                                        page_mode: page_mode_MetCraft,
                                        user_name: userName,
                                        user_id: userId,
                                        storage_key: storageKey,
                                        storage_space: storageSpace,
                                        storageData: projectData.storage_json,
                                        current_path: storageKey + '/' + 'projects' + '/',
                                        current_fold_name: 'projects',
                                        sessionId_back: receivedSessionId,
                                        callback_url: callback_url,
                                    };
                                    navigate(`/project-manager/${storageKey}`, {state: userDataForProject});
                                } else {
                                    console.error("Error in project selection:\n", projectResponse.data.response_data.error_message);
                                }
                            }).catch(error => {
                                console.error("Error fetching project data:", error);
                            });
                        } else {
                            // Use the login response data for storage or other modes and navigate accordingly
                            const userDataForStorage = {
                                page_mode: page_mode_MetCraft,
                                user_name: userName,
                                user_id: userId,
                                storage_space: storageSpace,
                                storage_key: storageKey,
                                storageData: data_info.storage_json,
                                current_path: current_path,
                                current_fold_name: 'useruploads',
                                sessionId_back: receivedSessionId,
                                callback_url: callback_url,
                            };
                            navigate(`/storage/${storageKey}`, {state: userDataForStorage});
                        }
                    } else {
                        console.error("Error login:\n", response.data.response_data.error_message);
                    }
                })
                .catch(error => {
                    console.error("Error logging in:", error);
                });

        	sessionStorage.setItem('jwt', token);
        }

    }, [location, csrfToken]);


    const handleSubmit = (event) => {

    };


    return (<div className="container">

    </div>);
};

export default Login_MetCraft;
