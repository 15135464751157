import React, {useState} from 'react';
import './login.css';

const Register = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        // handle the submit event, e.g. by sending the data to an API
        console.log(`Registering with username: ${username}, password: ${password} and email: ${email}`);
    };

    return (<div className="container">
        <h2>Register Page</h2>
        <form onSubmit={handleSubmit}>
            <div className="inputField">
                <label htmlFor="username">Username:</label>
                <input
                    type="text"
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />
            </div>
            <div className="inputField">
                <label htmlFor="email">Email:</label>
                <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
            </div>
            <div className="inputField">
                <label htmlFor="password">Password:</label>
                <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
            </div>
            <button className="button" type="submit">Register</button>
        </form>
    </div>);
};

export default Register;
